import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, Suspense as _Suspense, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "grid" }
const _hoisted_2 = { class: "col-12 lg:col-4" }
const _hoisted_3 = {
  key: 0,
  class: "card"
}
const _hoisted_4 = { class: "p-fluid" }
const _hoisted_5 = {
  key: 1,
  class: "card"
}
const _hoisted_6 = { class: "p-fluid" }
const _hoisted_7 = {
  key: 2,
  class: "card"
}
const _hoisted_8 = { class: "p-fluid" }
const _hoisted_9 = { class: "card" }
const _hoisted_10 = { class: "p-fluid" }
const _hoisted_11 = { class: "col-12 lg:col-4" }
const _hoisted_12 = { class: "card" }
const _hoisted_13 = { class: "p-fluid" }
const _hoisted_14 = { class: "col-12 lg:col-4" }
const _hoisted_15 = { class: "card" }
const _hoisted_16 = { class: "p-fluid" }
const _hoisted_17 = {
  key: 0,
  class: "card"
}
const _hoisted_18 = { class: "p-fluid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PrimeVueButton = _resolveComponent("PrimeVueButton")!
  const _component_FieldText = _resolveComponent("FieldText")!
  const _component_FieldDropdownNext = _resolveComponent("FieldDropdownNext")!
  const _component_FieldTextarea = _resolveComponent("FieldTextarea")!
  const _component_Macros = _resolveComponent("Macros")!
  const _component_PrimeVueSkeleton = _resolveComponent("PrimeVueSkeleton")!
  const _component_FieldImageBase64Cropper = _resolveComponent("FieldImageBase64Cropper")!
  const _component_Entry = _resolveComponent("Entry")!

  return (_openBlock(), _createBlock(_component_Entry, {
    title: _ctx.$t('creative'),
    entry: _ctx.creative,
    "entry-errors-map": _ctx.creativeErrorsMap,
    "onUpdate:entry-errors-map": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.creativeErrorsMap) = $event)),
    "entry-fetch-handler": _ctx.fetchCreative,
    "entry-store-handler": _ctx.storeCreative,
    "href-prefix": "creatives",
    "on-entry-store-redirect-to-entries": ""
  }, {
    "buttons-col-2-prepend": _withCtx(() => [
      (!!_ctx.creative.id)
        ? (_openBlock(), _createBlock(_component_PrimeVueButton, {
            key: 0,
            class: "p-button-secondary ml-4 mb-0",
            label: _ctx.$t('clone'),
            icon: "pi pi-copy",
            onClick: _ctx.cloneCreative
          }, null, 8, ["label", "onClick"]))
        : _createCommentVNode("", true)
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.creative.id)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_FieldText, {
                    "model-value": _ctx.creative.id,
                    label: "ID",
                    disabled: ""
                  }, null, 8, ["model-value"])
                ])
              ]))
            : _createCommentVNode("", true),
          (_ctx.user.isSuperAdmin())
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_FieldDropdownNext, {
                    modelValue: _ctx.creative.project_id,
                    "onUpdate:modelValue": [
                      _cache[0] || (_cache[0] = ($event: any) => ((_ctx.creative.project_id) = $event)),
                      _ctx.onProjectChange
                    ],
                    modelModifiers: { number: true },
                    name: "project_id",
                    "errors-map": _ctx.creativeErrorsMap,
                    label: _ctx.$t('project'),
                    loading: _ctx.projectsLoading,
                    disabled: !!_ctx.creative.id,
                    options: _ctx.projects.data,
                    "option-value": "id",
                    "option-label": "name"
                  }, null, 8, ["modelValue", "errors-map", "label", "loading", "disabled", "options", "onUpdate:modelValue"])
                ])
              ]))
            : _createCommentVNode("", true),
          (_ctx.user.isStaff())
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_component_FieldDropdownNext, {
                    modelValue: _ctx.creative.user_id,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.creative.user_id) = $event)),
                    modelModifiers: { number: true },
                    name: "user_id",
                    "errors-map": _ctx.creativeErrorsMap,
                    label: _ctx.$t('user'),
                    loading: _ctx.usersLoading,
                    disabled: !!_ctx.creative.id,
                    options: _ctx.users.data,
                    "option-value": "id",
                    "option-label": "compiled_name",
                    filter: ""
                  }, null, 8, ["modelValue", "errors-map", "label", "loading", "disabled", "options"])
                ])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createVNode(_component_FieldText, {
                modelValue: _ctx.creative.name,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.creative.name) = $event)),
                modelModifiers: { trim: true },
                name: "name",
                "errors-map": _ctx.creativeErrorsMap,
                label: _ctx.$t('name')
              }, null, 8, ["modelValue", "errors-map", "label"])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", _hoisted_13, [
              _createVNode(_component_FieldText, {
                modelValue: _ctx.creative.title,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.creative.title) = $event)),
                modelModifiers: { trim: true },
                name: "title",
                "errors-map": _ctx.creativeErrorsMap,
                label: _ctx.$t('title'),
                help: `${_ctx.$t('max_length')}: 32 ${_ctx.$t('chars')}`
              }, null, 8, ["modelValue", "errors-map", "label", "help"]),
              _createVNode(_component_FieldTextarea, {
                modelValue: _ctx.creative.description,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.creative.description) = $event)),
                modelModifiers: { trim: true },
                name: "description",
                "errors-map": _ctx.creativeErrorsMap,
                label: _ctx.$t('description'),
                help: `${_ctx.$t('max_length')}: 128 ${_ctx.$t('chars')}`
              }, null, 8, ["modelValue", "errors-map", "label", "help"]),
              (_openBlock(), _createBlock(_Suspense, null, {
                default: _withCtx(() => [
                  _createVNode(_component_Macros, {
                    class: "my-3",
                    macros: ['city']
                  })
                ]),
                fallback: _withCtx(() => [
                  _createVNode(_component_PrimeVueSkeleton, { height: "10px" })
                ]),
                _: 1
              })),
              (_openBlock(), _createBlock(_Suspense, null, {
                default: _withCtx(() => [
                  _createVNode(_component_FieldImageBase64Cropper, {
                    modelValue: _ctx.creativeMedia.icon_base64,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.creativeMedia.icon_base64) = $event)),
                    "image-url": _ctx.creative.icon_url,
                    name: "icon_base64",
                    "errors-map": _ctx.creativeErrorsMap,
                    label: `${_ctx.$t('icon')} (192x192)`,
                    "max-width": 128,
                    "aspect-ratio": 1
                  }, null, 8, ["modelValue", "image-url", "errors-map", "label"])
                ]),
                fallback: _withCtx(() => [
                  _createVNode(_component_PrimeVueSkeleton, { height: "50px" })
                ]),
                _: 1
              })),
              (_openBlock(), _createBlock(_Suspense, null, {
                default: _withCtx(() => [
                  _createVNode(_component_FieldImageBase64Cropper, {
                    modelValue: _ctx.creativeMedia.image_base64,
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.creativeMedia.image_base64) = $event)),
                    "image-url": _ctx.creative.image_url,
                    name: "image_base64",
                    "errors-map": _ctx.creativeErrorsMap,
                    label: `${_ctx.$t('image')} (360x240 / 720x480)`,
                    "max-width": 256,
                    "aspect-ratio": 3/2
                  }, null, 8, ["modelValue", "image-url", "errors-map", "label"])
                ]),
                fallback: _withCtx(() => [
                  _createVNode(_component_PrimeVueSkeleton, { height: "50px" })
                ]),
                _: 1
              }))
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_14, [
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("div", _hoisted_16, [
              _createVNode(_component_FieldDropdownNext, {
                modelValue: _ctx.creative.approval_status,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.creative.approval_status) = $event)),
                modelModifiers: { number: true },
                name: "approval_status",
                "errors-map": _ctx.creativeErrorsMap,
                label: _ctx.$t('approval_status'),
                disabled: _ctx.user.isAffiliate(),
                options: _ctx.CREATIVE_APPROVAL_STATUSES,
                "option-label-translate": ""
              }, null, 8, ["modelValue", "errors-map", "label", "disabled", "options"])
            ])
          ]),
          (!!_ctx.creative.id)
            ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                _createElementVNode("div", _hoisted_18, [
                  _createVNode(_component_FieldText, {
                    "model-value": _ctx.creative.created_at,
                    label: _ctx.$t('created_at'),
                    disabled: ""
                  }, null, 8, ["model-value", "label"]),
                  _createVNode(_component_FieldText, {
                    "model-value": _ctx.creative.updated_at,
                    label: _ctx.$t('updated_at'),
                    disabled: ""
                  }, null, 8, ["model-value", "label"])
                ])
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 1
  }, 8, ["title", "entry", "entry-errors-map", "entry-fetch-handler", "entry-store-handler"]))
}