
import axios from 'axios';

import {
  defineComponent,
  defineAsyncComponent,
  ref,
  reactive,
  onMounted,
} from 'vue';

import {
  GenericObject,
  ErrorsMap,
  Projects,
  Users,
  Creative,
} from '@/types';

import {
  ENTRIES,
  USER_TYPE_AFFILIATE,
  CREATIVE_APPROVAL_STATUS_PENDING_APPROVAL,
  CREATIVE_APPROVAL_STATUSES,
} from '@/libs/consts';

import useUser from '@/composable/useUser';

import useEntries from '@/composable/useEntries';

import useEntriesNext from '@/composable/useEntries@next';

import { useI18n } from 'vue-i18n';

export default defineComponent({
  components: {
    Macros: defineAsyncComponent(() => import('@/components/Macros.vue')),
    FieldImageBase64Cropper: defineAsyncComponent(() => import('@/components/FieldImageBase64Cropper.vue')),
  },
  setup() {
    const i18n = useI18n();

    const { activeUser: user } = useUser();

    const {
      entries: projects,
      entriesLoading: projectsLoading,
      fetchEntries: fetchProjects,
    } = useEntriesNext<Projects>('/api/project/getProjects', ENTRIES);

    const {
      entries: users,
      entriesLoading: usersLoading,
      fetchEntries: fetchUsers,
    } = useEntriesNext<Users>('/api/user/getUsers', ENTRIES);

    const { getEntryByRoute } = useEntries();

    const creative = reactive<Creative>({
      approval_status: CREATIVE_APPROVAL_STATUS_PENDING_APPROVAL,
    });

    const creativeMedia = reactive<GenericObject>({
      icon_base64: null,
      image_base64: null,
    });

    const creativeErrorsMap = ref<ErrorsMap>({});

    const fetchUsersWrapper = async (): Promise<void> => fetchUsers({
      project_id: user.isSuperAdmin() ? creative.project_id : null,
      type: USER_TYPE_AFFILIATE,
    });

    const fetchCreative = async (): Promise<void> => {
      const entry = await getEntryByRoute('/api/creative/getCreativeById');

      if (entry) {
        Object.assign(creative, entry);
      }

      Promise.all([
        user.isSuperAdmin() ? fetchProjects() : Promise.resolve(),
        user.isStaff() ? fetchUsersWrapper() : Promise.resolve(),
      ]);
    };

    const storeCreative = async (): Promise<void> => {
      const response = await axios.post(
        '/api/creative/storeCreative',
        {
          ...creative,
          ...creativeMedia,
        },
      );

      Object.assign(creative, response.data);
    };

    const fetchImageAsBase64 = async (url: string): Promise<string | void | ArrayBuffer | null> => {
      const response = await fetch(url);

      const blob = await response.blob();

      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onloadend = (): void => resolve(reader.result);

        reader.onerror = (error): void => reject(error);

        reader.readAsDataURL(blob);
      });
    };

    const cloneCreative = async (): Promise<void> => {
      creative.id = undefined;

      creative.name += ` - ${i18n.t('copy_female')}`;

      if (creative.icon_url) {
        creativeMedia.icon_base64 = await fetchImageAsBase64(creative.icon_url);
      }

      if (creative.image_url) {
        creativeMedia.image_base64 = await fetchImageAsBase64(creative.image_url);
      }
    };

    const onProjectChange = (): void => {
      creative.user_id = undefined;

      fetchUsersWrapper();
    };

    onMounted((): void => {
      Promise.all([
        user.isSuperAdmin() ? fetchProjects() : Promise.resolve(),
        user.isAffiliate() ? Promise.resolve() : fetchUsersWrapper(),
      ]);
    });

    return {
      CREATIVE_APPROVAL_STATUSES,
      user,
      projectsLoading,
      projects,
      usersLoading,
      users,
      creative,
      creativeMedia,
      creativeErrorsMap,
      fetchCreative,
      storeCreative,
      cloneCreative,
      onProjectChange,
    };
  },
});
